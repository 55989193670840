<!--
 * @Author: John
 * @Date: 2022-03-02 14:56:30
 * @LastEditTime: 2023-09-14 08:46:29
 * @LastEditors: John Li john.li@xiazhi.co
 * @Description: 添加交易记录
 * @FilePath: \jjms_ui\src\components\add-transaction.vue
 * @Copyright John
-->
<template>
    <div>
        <el-form :model="formData" :rules="formRule" ref="addTransForm" label-width="120px" :inline-message="true">
            <jj-select v-model="jjSelectObj" v-if="!isEditMode" />
            <el-input v-model="formData.jjCode" type="hidden"></el-input>
            <el-input v-model="formData.jjName" type="hidden"></el-input>
            <el-form-item v-if="isEditMode" label="基金代码" prop="jjCode">
                <el-input v-model="formData.jjCode" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item v-if="isEditMode" label="基金名称" prop="jjName">
                <el-input v-model="formData.jjName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="交易类型" prop="transactionType">
                <el-select v-model="formData.transactionType" style="width: 100%" @change="transactionTypeChangeHandler">
                    <el-option label="申购" value="1"></el-option>
                    <el-option label="赎回" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="交易时间" prop="transactionTime">
                <el-date-picker
                    v-model="formData.transactionTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期" style="width: 100%">
                    </el-date-picker>
            </el-form-item>
            <el-form-item v-if="isEditMode" label="交易日期" prop="transactionDate">
                <el-input v-model="formData.transactionDate" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item v-if="formData.transactionType == '1' || isEditMode" :label="label.transactionMoney" prop="transactionMoney">
                <el-input v-model="formData.transactionMoney"></el-input>
            </el-form-item>
            <el-form-item v-if="formData.transactionType == '2' || isEditMode" :label="label.fundShare" prop="fundShare">
                <el-input v-model="formData.fundShare"></el-input>
            </el-form-item>
            <el-form-item v-if="isEditMode" label="手续费" prop="handlineFee">
                <el-input v-model="formData.handlineFee"></el-input>
            </el-form-item>
            <el-form-item v-if="isEditMode" label="当日净值" prop="networth">
                <el-input v-model="formData.networth"></el-input>
            </el-form-item>
            <el-form-item v-if="isEditMode" :label="label.confirmMoney" prop="confirmMoney">
                <el-input v-model="formData.confirmMoney"></el-input>
            </el-form-item>
            <el-form-item v-if="isEditMode" label="份额确认日期" prop="confirmDate">
                <el-input v-model="formData.confirmDate" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item v-if="isEditMode" label="交易状态" prop="status">
                <el-select v-model="formData.status" style="width: 100%">
                    <el-option label="确认中" value="1"></el-option>
                    <el-option label="已确认" value="2"></el-option>
                </el-select>
            </el-form-item>
            <template v-if="isFirstTrans">
                <el-form-item label="已累计投入金额" prop="totalMoney">
                    <el-input v-model="formData.totalMoney" placeholder="请填写使用本系统前该基金已投入金额（不知道就和持仓总市值填一样），如果没有留空"></el-input>
                </el-form-item>
                <el-form-item label="已持有份额" prop="totalFoundShare">
                    <el-input v-model="formData.totalFoundShare" placeholder="请填写使用本系统前该基金已持仓份额，如果没有留空"></el-input>
                </el-form-item>
                <el-form-item label="当前持仓总市值" prop="totalMarketValue">
                    <el-input v-model="formData.totalMarketValue" placeholder="请填写使用本系统前该基金已持仓市值，如果没有留空"></el-input>
                </el-form-item>
            </template>
            <el-form-item v-if="!isDialog">
                <el-button type="primary" @click="submit">保存</el-button>
            </el-form-item>
            <el-form-item v-if="!isEditMode">
                <div style="color: red;">已累计投入金额、已持有份额、当前持仓总市值只有第一次交易，且之前已有持仓才需要填写</div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import api from '../api/req_api'
import jjSelect from './jj-select.vue'

export default {
    name: 'addTransForm',  // 添加定投表单
    components: {
        jjSelect
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        isDialog: Boolean
    },
    data() {
        // 当交易类型为赎回时，赎回份额是必填项
        let validateFundShare = (rule, value, callback) => {
            if(this.formData.transactionType == '2') {
                if(!value) {
                    callback(new Error('交易类型为赎回时，必须填写赎回份额！'));
                    return;
                }
            }
            callback();
        };
        let validateTransactionMoney = (rule, value, callback) => {
            if(this.formData.transactionType == '1') {
                if(!value) {
                    callback(new Error('交易类型为申购时，必须填写申购金额！'));
                    return;
                }
            }
            callback();
        };
        return {
            isFirstTrans: true,
            jjSelectObj: null,
            label: {
                transactionMoney: '申购金额',
                fundShare: '确认份额',
                confirmMoney: '确认金额'
            },
            formData: {
                jjName: '',
                jjCode: '',
                transactionDate:'',
                transactionType: '1'
            },
            formRule: {
                jjName: [
                    {required: true, message: '请输入基金名称', trigger: 'select'}
                ],
                jjCode: [
                    {required: true, message: '请输入基金代码', trigger: 'blur'}
                ],
                transactionTime: [
                    {required: true, message: '请输入交易时间', trigger: 'blur'}
                ],
                transactionType: [
                    {required: true, message: '请选择交易类型', trigger: 'blur'}
                ],
                nextDay: [
                    {required: true, message: '请输入下次定投日期', trigger: 'blur'}
                ],
                fundShare: [
                    { validator: validateFundShare, trigger: 'blur'}
                ],
                transactionMoney: [
                    { validator: validateTransactionMoney, trigger: 'blur'}
                ],
            },
            isEditMode: false
        }
    },
    methods: {
        transactionTypeChangeHandler(val) {
            console.log('val: ' + val)
            if(!val) {
                return;
            }
            if(val == '1') {
                // 申购
                this.label.transactionMoney = '申购金额';
                this.label.fundShare = '确认份额';
                this.label.confirmMoney = '确认金额';
            } else {
                // 赎回
                this.label.transactionMoney = '赎回金额';
                this.label.fundShare = '赎回份额';
                this.label.confirmMoney = '到账金额';
            }
        },
        submit(callback) {
            this.$refs['addTransForm'].validate((valid) => {
                if (valid) {
                    api.saveTrans(this.formData)
                        .then(data => {
                            if(data != null) {
                                this.$message.info('添加成功！');
                                this.formData = {};
                                this.$emit('update:data', this.formData);   // 通知父组件子组件已修改属性值
                                if(callback) callback();
                            }
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        initData(data) {
            console.log('initData data: ' + data)
            if(!data) {
                this.jjSelectObj = {jjCode: '', jjName: ''};
                this.formData = {};
                this.isEditMode = false;  // 添加模式
            } else {
                data.transactionType = data.transactionType+'';  // 修复数值类型不会默认选中问题
                data.status = data.status+'';  // 修复数值类型不会默认选中问题
                this.formData = data;
                this.isEditMode = true;  // 编辑模式
            }
        },
        // yyyy-MM-dd HH:mm:ss
        formatDate(date, fmt) {
        var o = {
            "M+": date.getMonth() + 1, //月份 
            "d+": date.getDate(), //日 
            "H+": date.getHours(), //小时 
            "m+": date.getMinutes(), //分 
            "s+": date.getSeconds(), //秒 
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
            "S": date.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
        }
    },
    created(){
        this.initData(this.data);
    },
    watch: {
        data: function(newVal) {
            this.initData(newVal);
        },
        jjSelectObj: function(newVal) {
            if(!newVal || !newVal.jjCode) {
                this.formData.jjCode = '';
                this.formData.jjName = '';
            } else {
                console.log(`formData.jjCode: ,${newVal.jjCode} formData.jjName: ${newVal.jjName}`)
                this.formData.jjCode = newVal.jjCode;
                this.formData.jjName = newVal.jjName;

                // 选择完基金后请求后台确认该基金是否是第一次录入交易记录
                api.queryTransList(null, null, newVal.jjCode)
                    .then(data => {
                        if(data == null || !(data instanceof Array) || data.length < 1) {
                            this.isFirstTrans = true;
                        } else {
                            this.isFirstTrans = false;
                        }
                    });

                // 将交易时间默认当前时间
                this.formData.transactionTime = this.formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
            }
        }
    }
}
</script>